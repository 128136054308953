import React, { useLayoutEffect, useState, useEffect } from 'react';
import { LeftArrowButton, RightArrowButton, SliderContainer, SliderElement, SliderWrapper } from './StyledComponents';

const Slider = ({ children }) => {
  const [direction, setDirection] = useState(0); // Direction of slide movement (-1 for previous, 1 for next, 0 for no movement)
  const [length] = useState(children.length); // Length of the children array
  const [movement, setMovement] = useState(0); // Controls the movement animation of the slider
  const [imgs, setImgs] = useState(children); // Array of images or content to be displayed in the slider
  const [touchPosition, setTouchPosition] = useState(null); // X position of the initial touch event
  const [showEnd, setShowEnd] = useState(false); // Determines whether to show the end of the slider when reaching the last slide

  useEffect(() => {
    // Update movement and showEnd states when direction changes
    direction === -1 ? setShowEnd(true) : setShowEnd(false);
    setMovement(direction);
  }, [direction]);

  useLayoutEffect(() => {
    let arrayShiftTimeout;
    if (movement === 1) {
      // Shift the array to the left when moving to the next slide
      let temp = [...imgs];
      temp.push(temp.shift());
      arrayShiftTimeout = setTimeout(() => setImgs(temp), 250);
    } else if (movement === -1) {
      // Shift the array to the right when moving to the previous slide
      let temp = [...imgs];
      temp.unshift(temp.pop());
      setImgs(temp);
      arrayShiftTimeout = setTimeout(() => imgs.unshift(imgs.pop()));
    }
    let directionTimeout = setTimeout(() => setDirection(0), 250); // Reset the direction after the animation completes

    return () => {
      clearTimeout(arrayShiftTimeout);
      clearTimeout(directionTimeout);
    };
  }, [movement]);

  const next = () => {
    setDirection(1); // Set direction to 1 to move to the next slide
  };

  const prev = () => {
    setDirection(-1); // Set direction to -1 to move to the previous slide
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown); // Store the initial touch position
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }
    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;
    if (diff > 5) {
      next(); // Move to the next slide if the touch position difference is greater than 5
    } else if (diff < -5) {
      prev(); // Move to the previous slide if the touch position difference is less than -5
    }
    setTouchPosition(null);
  };

  return (
    <SliderWrapper>
      <SliderContainer onTouchStart={length < 2 ? () => {} : handleTouchStart} onTouchMove={length < 2 ? () => {} : handleTouchMove}>
        <LeftArrowButton className={length < 2 ? 'hide' : ''} onClick={prev}/>
        <SliderElement className={`${movement === 1 ? 'next' : movement === -1 ? 'prev' : 'noanimate'} ${showEnd ? 'showend' : ''}`}>
          {imgs ? imgs : []}
        </SliderElement>
        <RightArrowButton className={length < 2 ? 'hide' : ''} onClick={next}  />
      </SliderContainer>
    </SliderWrapper>
  );
};

export default Slider;