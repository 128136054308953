import React, { useContext, useEffect } from 'react';
import PuppyCard from '../components/cards/PuppyCard';
import { ReactComponent as Picture } from '../assets/bdpLogo.svg';
import {
  ColContainer,
  ContentContainer,
  Header,
  PuppyImage,
} from './StyledComponents';
import { AppContext } from '../context/AppContext';
import { useParams } from 'react-router-dom';
import { useAvailableDogs } from '../hooks/apiHooks/useAvailableDogs';
import { useDogPictures } from '../hooks/apiHooks/useDogPictures';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

const Pic = styled(Picture)`
  fill: ${props =>
    props.gender === 'male'
      ? props => props.theme.text2
      : props => props.theme.font};
`;

const Puppies = () => {
  const { availableDogs, dogPictures } = useContext(AppContext);
  const { id } = useParams();
  const { response, loading, fetchAvailableDogs } = useAvailableDogs({
    currentData: availableDogs,
  });
  const { fetchDogPictures } = useDogPictures({
    currentData: dogPictures,
  });
  useEffect(() => {
    fetchAvailableDogs();
    fetchDogPictures();
  }, []);
  const getPicture = dog => {
    let img = <Pic gender={dog.gender} />;
    dogPictures &&
      dogPictures.map(dogPicture => {
        if (dogPicture.dog.id == dog.id && dogPicture.is_primary == true) {
          img = (
            <PuppyImage
              width={500}
              height={500}
              key={dogPicture.id}
              src={dogPicture.picture.link}
              alt={dogPicture.picture.description}
            />
          );
        }
      });
    return img;
  };
  if (availableDogs.length>0) {
    return (
      <>
        <Helmet>
          <title>Big Dawg Plantation Puppies</title>
          <meta
            name="description"
            content="Big Dawg Plantation Available Puppies"
          />
          <link rel="canonical" href="/puppies" />
        </Helmet>
        <ColContainer>
          <Header>Our Avaliable Puppies</Header>
          <ContentContainer>
            {availableDogs &&
              availableDogs.map(availableDog => {
                return (
                  <PuppyCard
                    picture={getPicture(availableDog)}
                    key={availableDog.id}
                    dog={availableDog}
                  />
                );
              })}
          </ContentContainer>
        </ColContainer>
      </>
    );
  }
  return (
    <ColContainer>
      <Header>No Puppies Available Currently</Header>
      <h3>Please Checkback or Contact us through Email!</h3>
    </ColContainer>
  );
};

export default Puppies;
