import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AdminVerticalNavBar from '../components/navigation/adminVerticalNavBar/AdminVerticalNavBar';
import { RowContainer } from './StyledComponents';
import styled from 'styled-components';

const AdminContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  padding-top: 5rem;
  width: 100%;
  height: 100vh;
`;
const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 3.5em;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const Admin = () => {
  return (
    <>
      <Helmet>
        <title>Big Dawg Plantation Admin Panel</title>
        <meta
          name="description"
          content="Big Dawg Plantation Admin Panel"
        />
        <link rel="canonical" href="/admin" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <AdminContainer>
        <NavContainer>
          <AdminVerticalNavBar />
        </NavContainer>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </AdminContainer>
    </>
  );
};

export default Admin;
