import { useState } from 'react';
import { useAxiosFunction } from './useAxios';
import axios from '../../api/BDPAPI';

export const useContact = ({ onSuccess, onFail }) => {
  const { response, loading, axiosFetch } = useAxiosFunction();
  const [sent, setSent] = useState(false);

  const sendEmail = async data => {
    if (!sent) {
      let res = {};
      // console.log(
      // 'fetching forced:' + forceFetch + ' url:/ip',
      // );
      res = await axiosFetch(
        {
          axiosInstance: axios,
          method: 'POST',
          url: '/communications/contact',
          requestConfig: {},
        },
        data,
      ).catch(e => console.error(e));

      if (res) {
        console.log(res.data);
        setSent(true);
        onSuccess();
      } else {
        onFail();
      }
    }
  };

  return { response, loading, sendEmail };
};
