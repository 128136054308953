import React from 'react';

const Facilities = () => {
  return (
    // <p>
    //     Our commitment to providing the utmost care for our Boxers starts with our top-notch facilities. We have spacious kennels built on a concrete slab, ensuring a clean and comfortable environment for our dogs. Each kennel is equipped with individual runs, allowing our Boxers to enjoy fresh air and exercise regularly. Additionally, we have a dedicated house for whelping, where our expecting mothers receive special care and attention during the birthing process. We maintain a hygienic and well-maintained facility, creating a safe and nurturing space for our beloved Boxers.
    // </p>
    <p>
      Our commitment to providing the utmost care for our Boxers starts
      with our top-notch facilities. Most of our Boxers live inside our
      home but we also have a few that prefer their own space that live in
      our on site facility. We have spacious kennels built on a concrete
      slab, ensuring a clean and comfortable environment for our dogs. Each
      kennel is equipped with individual lawn areas, allowing our Boxers to
      enjoy fresh air and exercise everyday. Each dog also has their own
      unique enclosure to shelter them from all elements in times of heat,
      cold or rain. Additionally, we have a dedicated house for whelping,
      where our expecting mothers receive special care and attention during
      the birthing process. We maintain a hygienic and well-maintained
      facility, creating a safe and nurturing space for our beloved Boxers.
      We are regularly inspected by AKC and happily maintain an A+ rating
      with them. (Post pictures of the kennels)
    </p>
  );
};

export default Facilities;
