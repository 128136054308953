import React from 'react';
import { useContext } from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import { AppContext } from '../../context/AppContext';
import { useNotifications } from '../../hooks/useNotifications';
import Notification from './Notification';

const NotificationPortalContainer = styled.div`
  gap: 10px;
  display: flex;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 9;
  flex-direction: column;
`;

export const NotificationPortal = () => {
  const { notifications, setNotifications } = useContext(AppContext);
  const { loaded, portalId } = useNotifications();
  const removeNotification = id => {
    setNotifications(notifications.filter(n => n.id !== id));
  };
  return loaded ? (
    ReactDom.createPortal(
      <NotificationPortalContainer>
        {notifications.length > 0 &&
          notifications.map(n => (
            <Notification
              key={n.id}
              type={n.type}
              content={n.content}
              duration={n.duration}
              onClose={() => removeNotification(n.id)}
            />
          ))}
      </NotificationPortalContainer>,
      document.getElementById(portalId),
    )
  ) : (
    <></>
  );
};
