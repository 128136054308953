import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Form,
  FormContainer,
  FormHeader,
  Input,
  InputGroup,
  Label,
} from './StyledComponents';
import { AppContext } from '../../context/AppContext';
import { useContact } from '../../hooks/apiHooks/useContact';

const ContactForm = () => {
  const { addNotification } = useContext(AppContext);

  const onSuccess = () => {
    addNotification({
      content: `Your Message has been Sent, ${formik.values.name}!`,
      type: 'success',
      duration: 3000,
      id: 'contactSuccess',
    });
    formik.resetForm();
    formik.setSubmitting(false);
  };
  const onFail = () => {
    addNotification({
      content: `Your Message Failed to be Sent, ${formik.values.name}!`,
      type: 'error',
      id: 'contactError',
    });
  };

  const { sendEmail } = useContact({ onSuccess, onFail });

  const onSubmit = async values => {
    console.log(values);
    sendEmail(values);
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup
      .string()
      .email('Invalid email')
      .required('Email is required'),
    message: yup.string().required('Message is required'),
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    initialErrors: { manuallyIndicatingAn: 'Error' },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <FormContainer>
      <FormHeader>Contact Us!</FormHeader>
      <Form onSubmit={formik.handleSubmit}>
        <InputGroup>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder=" "
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          <Label htmlFor="name">Name:</Label>
        </InputGroup>
        <InputGroup>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder=" "
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <Label htmlFor="email">Email:</Label>
        </InputGroup>
        <InputGroup>
          <Input
            as="textarea"
            id="message"
            name="message"
            placeholder=" "
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
          <Label htmlFor="message">Message:</Label>
        </InputGroup>
        <Button type="submit" disabled={!formik.isValid}>
          Submit
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ContactForm;
