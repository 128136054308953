import styled from "styled-components";

export const AppContainer = styled.div`
  width: 100%;
  min-height: 100%;
  position: relative;
  border: none;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scroll-behavior: smooth;
  margin: 0;
  color: ${props=>props.theme.primary};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: background-color 300ms linear;
  background-color: ${props=>props.theme.secondary};
  .custom-shape-divider-top-1682854520 {
    position: absolute;
    bottom: 15vh;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    
  }

  .custom-shape-divider-top-1682854520 svg {
      position: relative;
      display: block;
      width: calc(186% + 1.3px);
      height: 235px;
      transform: rotateY(180deg);
  }

  .custom-shape-divider-top-1682854520 .shape-fill {
      fill: #66bdfa;
  }
`

export const OutletContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw; 
  scroll-behavior: smooth;  
  
`
export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 13vh;
  justify-content: center;
  
 
`
export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 15vh;
  width: 100%;
  box-shadow: ${props=>props.theme.accent} 0 .2rem 2rem 0 ;
  background-color: ${props=>props.theme.primaryButton};
  
  
`
export const HiddenMainContentNav = styled.a`
    position: absolute;
    text-decoration:none;
    padding: .2rem;
    color:${props=>props.theme.text};
    transform: translate(-770%,-100%);
    background-color: ${props=>props.theme.primary};
    border-radius: 0 0 .5rem .5rem;
    z-index: 10;
    &:focus{
      transform: translate(-770%,0);
      position: relative;
    }
    
`