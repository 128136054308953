import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNotificationAutoClose } from '../../hooks/useNotificationAutoClose';

const NotificationBox = styled.div`
  background-color: ${props => props.color || '#fff'};
  box-sizing: border-box;
  padding: 0.75rem;
  border-radius: 0.35em;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: transform 300ms ease-in-out;
  width: 250px;
  z-index: 25;
  &.show {
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.5s
      ${props =>
        props.duration !== '' ? `,fadeOut 0.5s ${props.duration}` : ''};
  }
  &.hide {
    animation: fadeOut 0.5s;
  }
  &.success {
    background-color: ${props => props.theme.primaryButton};
  }
  &.error {
    background-color: ${props => props.theme.accent};
  }
  &.info {
    background-color: ${props => props.theme.secondary};
  }
  &.warning {
    background-color: ${props => props.theme.secondaryButton};
  }
  @keyframes fadeIn {
    from {
      transform: translate(100px, 0);
      opacity: 0;
    }
    to {
      transform: translate(-20px, 0);
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      transform: translate(0, 0);
      opacity: 1;
    }
    to {
      transform: translate(100px, 0);
      opacity: 0;
    }
  }
`;
const NotificationCloseButton = styled.button`
  position: absolute;
  top: 2px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Notification = ({ content, type, duration, onClose }) => {
  const [show, setShow] = useState(true);
  const notificationDuration = useMemo(() => {
    if (duration) {
      return duration / 1000 - 0.5 + 's';
    } else return '';
  }, [duration]);
  const classes = useMemo(
    () => `${type ? type : ''} ${show ? 'show' : 'hide'}`,
    [type, show],
  );
  const close = () => {
    setShow(false);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  useNotificationAutoClose(duration);

  return (
    <NotificationBox
      onClick={close}
      className={classes}
      duration={notificationDuration}
    >
      {content}
      <NotificationCloseButton>×</NotificationCloseButton>
    </NotificationBox>
  );
};

export default Notification;
