import { useEffect, useState } from 'react';

export const useAxiosFunction = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState();

  const axiosFetch = async (configObj, data = null) => {
    const { axiosInstance, method, url, requestConfig = {} } = configObj;

    try {
      // console.log('using axios for ' + method + ' ' + url);
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      let res;
      if (data !== null) {
        // console.log('data sent');
        res = await axiosInstance[method.toLowerCase()](url, data, {
          ...requestConfig,
          signal: ctrl.signal,
        });
      } else {
        // console.log('no data sent');
        res = await axiosInstance[method.toLowerCase()](url, {
          ...requestConfig,
          signal: ctrl.signal,
        });
      }
      // console.log(res);
      if (res.data) {
        // console.log(res.data);
        setResponse(res.data);
        return res;
      } else if (res) {
        // console.log(res);
        setResponse(res);
      }
    } catch (e) {
      // console.log(e.response.status);
      if (e.message !== 'canceled') {
        setError(e.message);
        throw e.message;
      }
    } finally {
      // console.log(loading);
      setLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      controller && controller.abort();
    };
  }, [controller]);

  return { response, error, loading, setError, axiosFetch };
};
