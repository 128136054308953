import React, { useContext, useEffect, useState } from 'react';
import {
  BoldLink,
  BoxContainer,
  FieldContainer,
  FieldError,
  FormContainer,
  FormError,
  Input,
  MutedLink,
  SubmitButton,
} from './common';
import { Marginer } from '../marginer';
import { AccountContext } from './accountContext';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import axios from '../../api/BDPAPI';
import axios from 'axios';
import { AppContext } from '../../context/AppContext';
import { useClearModal } from '../../hooks/useClearModal';
import { useSelf } from '../../hooks/apiHooks/useSelf';


const validationSchema = yup.object({
  username: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

export function LoginForm() {
  const { switchToSignup,navigate,from,isModal } = useContext(AccountContext);
  const { addNotification } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [clear] = useClearModal();
  const {fetchSelf,response,loading} = useSelf({});
  useEffect(()=>{
    if(Object.keys(response).length>0 && !loading && !isModal){
      console.log('running navigate')
      console.log(response)
        navigate(from,{replace:true})
      
    }
  },[response,loading])
  
  const onSubmit = async values => {
    setError(null);
    let bodyform = new FormData();
    bodyform.append('username', values.username);
    bodyform.append('password', values.password);
    try {
      const response = await axios.post(
        'https://api.bigdawgplantation.com/login',
        bodyform,
      );

      if (response) {
        sessionStorage.setItem('token', response.data.access_token);
        addNotification({
          content: `Welcome ${values.username}`,
          type: 'success',
          duration: 3000,
          id: 'loginSuccess',
        });
        fetchSelf()
        if(isModal){
          clear('signinModal')
        }
        
      }
    } catch (error) {
      console.log(error)
      addNotification({
        content: 'An Error has occured',
        type: 'error',
        id: 'loginError',
      });
      setError('invalid');
    }
    
  };
  const formik = useFormik({
    initialValues: { username: '', password: '' },
    initialErrors: { manuallyIndicatingAn: 'Error' },
    validateOnBlur: true,
    onSubmit,
    validationSchema,
  });

  return (
    <BoxContainer>
      {<FormError>{error ? error : ''}</FormError>}
      <FormContainer onSubmit={formik.handleSubmit}>
        <FieldContainer>
          <Input
            name="username"
            type="email"
            placeholder="Email"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FieldError>
            {formik.touched.username && formik.errors.username
              ? formik.errors.username
              : ''}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FieldError>
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : ''}
          </FieldError>
        </FieldContainer>
        <MutedLink href="#">Forgot your password?</MutedLink>
        <Marginer direction="vertical" margin="1em" />
        <SubmitButton disabled={!formik.isValid} type="submit">
          Signin
        </SubmitButton>
      </FormContainer>
      <Marginer direction="vertical" margin={5} />
      <MutedLink href="#">
        New customer?{' '}
        <BoldLink href="#" onClick={switchToSignup}>
          Signup
        </BoldLink>
      </MutedLink>
    </BoxContainer>
  );
}
