import { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { useAxiosFunction } from './useAxios';
import axios from '../../api/BDPAPI';

export const useAvailableDogs = ({
  currentData = [],
  onSuccess = null,
  onFail = null,
}) => {
  const { setAvailableDogs, setSelectedAvailableDog } = useContext(AppContext);
  const { response, loading, axiosFetch } = useAxiosFunction();
  const [fetched, setFetched] = useState(false);
  const fetchAvailableDogs = async (forceFetch = false) => {
    if (currentData.length === 0 || forceFetch) {
      if (!fetched || forceFetch) {
        //   console.log('fetching forced:' + forceFetch + ' url:/employees/');
        let res = await axiosFetch({
          axiosInstance: axios,
          method: 'GET',
          url: '/dogs/available',
        }).catch(e => console.error(e));
        setFetched(true);
        if (res) {
          setAvailableDogs(res.data);
          return res.data;
        }
      }
    }
  };  
  const getAvailableDog = async id => {
    try {
      let res = await axiosFetch({
        axiosInstance: axios,
        method: 'GET',
        url: `/dogs/available/${id}`,
      });
      if (res) {
        setSelectedAvailableDog(res.data);
        if (onSuccess !== null) {
          onSuccess();
        }
      }
    } catch (e) {
      if (onFail !== null) {
        onFail(e);
      }
    }
  };
  return {
    response,
    loading,
    fetchAvailableDogs,
    getAvailableDog,
  };
};
