import React from 'react';

const HealthGuarantee = () => {
  return (
    <p>
      We understand the importance of ensuring the well-being of our
      Boxers, which is why we offer a comprehensive health guarantee. All
      our puppies undergo regular veterinary check-ups, receive necessary
      vaccinations, and are dewormed as per the recommended schedule. We
      provide extensive health records for each puppy, along with
      information on their parentage and pedigree. Our health guarantee is
      a testament to our commitment to breeding healthy Boxers and offering
      peace of mind to our valued clients.
    </p>
  );
};

export default HealthGuarantee;
