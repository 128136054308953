import React from 'react';

const TrainingSocialization = () => {
  return (
    // <p>
    //     We believe in raising well-rounded Boxers who excel not only in physical attributes but also in temperament and behavior. Our puppies are introduced to early socialization and basic training from an early age. They are exposed to various stimuli, environments, and positive interactions to help them develop into confident and sociable dogs. Our dedication to their training and socialization ensures that our Boxers are adaptable, obedient, and well-behaved companions.
    // </p>
    <p>
      We believe in raising well-rounded Boxers who excel not only in
      physical attributes but also in temperament and behavior. Our puppies
      are introduced to early socialization and basic training from an
      early age. They are exposed to various stimuli, environments, and
      positive interactions to help them develop into confident and
      sociable dogs. Our dedication to their training and socialization
      ensures that our Boxers are adaptable, obedient, and well-behaved
      companions. We begin house training at 5 weeks of age and by the time
      they go to their new homes, at 8 weeks of age, they are well on their
      way to being fully trained. They come with instructions to keep them
      going in the right direction.
    </p>
  );
};

export default TrainingSocialization;
