import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.primary};
  margin: 2rem;
  font-weight: bold;
  font-size: larger;
  font-family: Roboto-Serif;
  border-bottom: 0.2em solid transparent;
  &.active {
    border-bottom: 0.2em solid ${props => props.theme.accent};
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
