import styled from "styled-components";


export const FormContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
`

export const FormHeader = styled.h2`
    color: ${props=>props.theme.primary};
`

export const Form = styled.form`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
`;

export const InputGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`
export const Input = styled.input`
    background-color: transparent;
    position: relative;
    outline: none;
    border: none;
    color: ${props=>props.theme.secondaryButton};
    padding: .2rem;
    border-bottom: 2px solid ${props=>props.theme.primary};
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.primaryButton} inset !important;
    box-shadow: 0 0 0 30px ${props => props.theme.primaryButton} inset !important;
    -webkit-text-fill-color: ${props => props.theme.secondaryButton};
  }
    
    &:focus + label{        
        transform: translateY(-1rem);
        font-size: .8rem;        
    }
    &:not(:placeholder-shown):not(:focus) + label {
    transform: translateY( -1rem);
    font-size: 0.8rem;
  }
    
`
export const Label = styled.label`
    position: absolute;
    transition: transform 200ms ease-in-out, font-size 200ms ease-in-out;
    color: ${props=>props.theme.secondary};
`

export const Button = styled.button`
    background-color: ${props=>props.theme.primary};
    cursor: pointer;
    outline: none;
    padding: .2rem;
    border: none;
    border: 1px solid ${props => props.theme.primaryButton};
    color: ${props => props.theme.primaryButton};
    border-radius: .5rem;
    :hover {
        border: 1px solid ${props => props.theme.secondaryButton};
    }
    :disabled {
        visibility: collapse;        
    }
`;