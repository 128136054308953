import React, { useState } from 'react';
import styled from 'styled-components';
import { LoginForm } from './loginForm';
import { SignupForm } from './signupForm';
import { AccountContext } from './accountContext';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const BoxContainer = styled.div`
  width: 35vw;
  max-width: 350px;
  min-width: 280px;
  height: 90vh;
  min-height: 25vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  /* border-radius: 19px; */
  background-color: ${props => props.theme.secondary};

  position: relative;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.8em;
  padding-bottom: 5.5em;
  z-index: 5;
`;

const BackDrop = styled.div`
  width: 140%;
  height: 500px;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  transform: rotate(75deg);
  top: -290px;
  left: -70px;
  background-color: ${props => props.theme.accent};
  &.animate {
    animation: expandDown 1s ease-in-out forwards,
      expandUp 1s 1s ease-in-out forwards;
  }
  @keyframes expandDown {
    to {
      width: 240%;
      height: 1100px;
      border-radius: 30%;
      transform: rotate(50deg);
    }
  }
  @keyframes expandUp {
    to {
      width: 140%;
      height: 550px;
      border-radius: 50%;
      transform: rotate(75deg);
    }
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  /* height: 50%; */
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.h2`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.24;
  color: ${props => props.theme.secondary};
  z-index: 10;
  margin: 0;
`;

const SmallText = styled.h3`
  color: ${props => props.theme.secondary};
  font-weight: 500;
  font-size: 11px;
  z-index: 10;
  margin: 0;
  margin-top: 7px;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 1.8em;
`;

export function AccountBox({ setting = '', isModal = false }) {
  const [isExpanded, setExpanded] = useState(false);
  const { theme } = useContext(AppContext);
  let location;
  let navigate;
  let from;
  let contextValue;
  if (!isModal) {
    location = useLocation();
    navigate = useNavigate();
    from = location.state?.from?.pathname || '/';
  }

  const [active, setActive] = useState(() => {
    if (setting != '') {
      return setting;
    }
    return 'signin';
  });
  const playExpandingAnimation = () => {
    setExpanded(true);
    setTimeout(() => {
      setExpanded(false);
    }, 3000);
  };
  const switchToSignup = () => {
    playExpandingAnimation();
    setTimeout(() => {
      setActive('signup');
    }, 1000);
  };

  const switchToSignin = () => {
    playExpandingAnimation();
    setTimeout(() => {
      setActive('signin');
    }, 1000);
  };
  if (isModal) {
    contextValue = { switchToSignup, switchToSignin, isModal };
  }
  if (!isModal) {
    contextValue = {
      switchToSignup,
      switchToSignin,
      navigate,
      from,
      isModal,
    };
  }

  return (
    <>
      <Helmet>
        <title>Big Dawg Plantation Login</title>
        <meta
          name="description"
          content="Big Dawg Plantation Login Page"
        />
        <link rel="canonical" href="/login" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {theme && (
        <AccountContext.Provider value={contextValue}>
          <BoxContainer>
            <TopContainer>
              <BackDrop className={isExpanded ? 'animate' : ''} />

              {active === 'signin' && (
                <HeaderContainer>
                  <HeaderText>Welcome</HeaderText>
                  <HeaderText>Back</HeaderText>
                  <SmallText>Please sign-in to continue!</SmallText>
                </HeaderContainer>
              )}
              {active === 'signup' && (
                <HeaderContainer>
                  <HeaderText>Create</HeaderText>
                  <HeaderText>Account</HeaderText>
                  <SmallText>Please sign-up to continue!</SmallText>
                </HeaderContainer>
              )}
            </TopContainer>
            <InnerContainer>
              {active === 'signin' && <LoginForm />}
              {active === 'signup' && <SignupForm />}
            </InnerContainer>
          </BoxContainer>
        </AccountContext.Provider>
      )}
    </>
  );
}
