import { uuid } from '../utils/componentUtil';
import { useEffect, useState } from 'react';

export const useModal = () => {
  const [loaded, setLoaded] = useState(false);
  const [portalId] = useState(`modal-portal-${uuid()}`);

  useEffect(() => {
    const div = document.createElement('div');
    div.id = portalId;
    //div.style = 'position: fixed; width:100%; height:100%; ';
    document.getElementsByTagName('body')[0].prepend(div);

    setLoaded(true);

    return () => document.getElementsByTagName('body')[0].removeChild(div);
  }, [portalId]);

  return { loaded, portalId };
};
