// import React from 'react';

// const Dogs = () => {
//   return <div>Dogs</div>;
// };

// export default Dogs;
import React, { useContext, useEffect } from 'react';
import { ReactComponent as Picture } from '../assets/bdpLogo.svg';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';
// import SingleCustomerPage from './singlePages/SingleCustomerPage';
// import SearchBar from '../components/searchbar/SearchBar';
// import { BoxContainer, InnerBoxContainer } from './common';
import { useParams } from 'react-router-dom';
import { useDogs } from '../hooks/apiHooks/useDogs';
import PuppyCard from '../components/cards/PuppyCard';
const Pic = styled(Picture)`
  fill: ${props =>
    props.gender === 'male'
      ? props => props.theme.text2
      : props => props.theme.font};
`;
const Dogs = () => {
  const { dogSearch, setDogSearch, dogs } = useContext(AppContext);
  const { id } = useParams();
  const { response, loading, fetchDogs } = useDogs({
    currentData: dogs,
  });
  useEffect(() => {
    setDogSearch(dogs);
  }, []);

  useEffect(() => {
    if (response.length === 0) {
      setDogSearch(dogs);
      fetchDogs(true);
    }
    if (!loading && response.length > 0) {
      if (dogs.length > 0) {
        setDogSearch(dogs);
      }
    }
    console.log(dogs);
  }, [dogs]);

  //   function createSkeleton() {
  //     let elements = [];
  //     for (let i = 0; i < 30; i++) {
  //       elements.push(<SkeletonCustomerCard key={i} />);
  //     }
  //     return elements;
  //   }

  //   if (id) {
  //     return (
  //       <div>
  //         <SingleCustomerPage />
  //       </div>
  //     );
  //   } else {
  return (
    <div>
      {/* <SearchBar values={customers} /> */}
      <div>
        {dogSearch &&
          dogSearch.map(dog => {
            return (
              <PuppyCard
                key={dog.id}
                picture={<Pic gender={dog.gender} />}
                dog={dog}
              />
            );
          })}
      </div>
    </div>
    // <div>dogs</div>
  );
};

export default Dogs;
