import axios from 'axios';

// const BASE_URL = 'http://192.168.2.233';
const BASE_URL = 'https://api.bigdawgplantation.com';
export default axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
