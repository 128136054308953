import React from 'react';
import { Helmet } from 'react-helmet-async';
import Accordion from '../components/accordion/Accordion';
import AccordionElement from '../components/accordion/AccordionElement';
import BDP from '../layouts/about/BDP';
import { AboutContainer, ContentContainer } from './StyledComponents';
import Boxers from '../layouts/about/Boxers';
import HealthGuarantee from '../layouts/about/HealthGuarantee';
import TrainingSocialization from '../layouts/about/TrainingSocialization';
import Visiting from '../layouts/about/Visiting';
import Facilities from '../layouts/about/Facilities';
import Delivery from '../layouts/about/Delivery';
import AKC from '../layouts/about/AKC';
// import Testimonials from '../layouts/about/Testimonials'

const About = () => {
  let accordionElements = [
    <AccordionElement
      key="bdp"
      title={'Big Dawg Plantation'}
      content={<BDP />}
    />,
    <AccordionElement
      key="boxers"
      title={'Boxers'}
      content={<Boxers />}
    />,
    <AccordionElement
      key="facilities"
      title={'Our Facilities'}
      content={<Facilities />}
    />,
    <AccordionElement
      key="HG"
      title={'Health Guarantee'}
      content={<HealthGuarantee />}
    />,
    <AccordionElement
      key="TS"
      title={'Training and Socialization'}
      content={<TrainingSocialization />}
    />,
    <AccordionElement
      key="visiting"
      title={'Visiting'}
      content={<Visiting />}
    />,
    <AccordionElement
      key="delivery"
      title={'Delivery'}
      content={<Delivery />}
    />,
    <AccordionElement key="akc" title={'AKC'} content={<AKC />} />,
    // <AccordionElement key='testimonies' title={"Testimonies"} content={<Testimonials/>}/>,
  ];
  return (
    <>
      <Helmet>
        <title>Big Dawg Plantation About Us</title>
        <meta
          name="description"
          content="Learn about Big Dawg Plantation and how we do things"
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <AboutContainer>
        <Accordion children={accordionElements} />
      </AboutContainer>
    </>
  );
};

export default About;
