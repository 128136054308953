import React, { useState, createContext } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import {
  mainTheme3,
  mainTheme,
  blueWhitePurple,
  blueOrange,
  greenWhite,
  greenBlue,
  lightPurple,
  purpleWhite,
  purpleDark,
  earthyOrange,
  allBlue,
} from '../utils/themes';
import { uuid } from '../utils/componentUtil';
// purpleDark,purpleWhite,greenBlue,blueBlack, Needs Work
const GlobalStyle = createGlobalStyle`
  body{
    background-color: ${props => props.theme.primary};
  }
`;

export const AppContext = createContext();

export const AppContextProvider = props => {
  const [theme, setTheme] = useState(blueWhitePurple);
  const [homeVisited, setHomeVisited] = useState(false);
  const [modals, setModals] = useState([]);
  const [animateNav, setAnimateNav] = useState(false);
  const [events, setEvents] = useState([]);
  const [user, setUser] = useState({});
  const [self, setSelf] = useState({});
  const [availableDogs, setAvailableDogs] = useState([]);
  const [selectedAvailableDog, setSelectedAvailableDog] = useState({});
  const [dogPictures, setDogPictures] = useState([]);
  const [selectedDogPictures, setSelectedDogPictures] = useState([]);
  const [dogSearch, setDogSearch] = useState([]);
  const [dogs, setDogs] = useState([]);

  const [hideModals, setHideModals] = useState(false);
  const addModal = modal => {
    setModals([
      ...modals,
      {
        ...modal,
        id: `${modal.id ? modal.id + '-' : ''}${uuid()}`,
      },
    ]);
  };

  const [notifications, setNotifications] = useState([]);
  const addNotification = notification => {
    setNotifications([
      ...notifications,
      {
        ...notification,
        id: `${notification.id ? notification.id + '-' : ''}${uuid()}`,
      },
    ]);
  };
  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        animateNav,
        setAnimateNav,
        events,
        setEvents,
        hideModals,
        setHideModals,
        modals,
        setModals,
        addModal,
        notifications,
        setNotifications,
        addNotification,
        self,
        setSelf,
        dogs,
        setDogs,
        availableDogs,
        setAvailableDogs,
        selectedAvailableDog,
        setSelectedAvailableDog,
        dogSearch,
        setDogSearch,
        dogPictures,
        setDogPictures,
        selectedDogPictures,
        setSelectedDogPictures,
        user,
        setUser,
        homeVisited,
        setHomeVisited,
      }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {props.children}
      </ThemeProvider>
    </AppContext.Provider>
  );
};
