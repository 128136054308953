export const mainTheme = {
    background: '#FFFDD0',
    text: '#1E212D',
    //altText: '#999BB8',
    // boldText: '',
    // error: '',
    success: '#EABF9F',
    foreground: '#FF2400',
    accent: '#7B3F00',
}
export const mainTheme1 = {
    background: '#FFF4E0',
    text: '#4D4D4D',
    // altText: '',
    // boldText: '',
    // error: '',
    // success: '',
    foreground: '#FFBF9B',
    accent: '#B46060',
}
export const mainTheme2 = {
    background: '#849574',
    text: '#1E212D',
    // altText: '',
    // boldText: '',
    // error: '',
    success: '#EABF9F',
    foreground: '#FAF3E0',
    accent: '#B68973',
}
export const mainTheme3 ={
    background: '#FAF9F6',
    text2: '#000033',
    text: '#FFCC99',
    accent: '#F28C28',
    accent2: '#0096FF',
    foreground: '#708090',
    success: '#32CD32',
    error: '#FF3131',
    warning: '#FAFA33',
    shadow: '#F9F6EE',
    shadow2: '#7393B3',
    font2: '#66bdfa',
    font: '#0d0f12',

}

export const blueOrange ={
    primary: '#0e0502',
    secondary: '#fdf1ed',
    primaryButton: '#6ac7ec',
    secondaryButton: '#f9ddd2',
    accent: '#22ace2', 
}

export const greenWhite = {
    primary: '#191e15',
    secondary: '#eff2ed', 
    primaryButton: '#759f78 ',
    secondaryButton: '#d8e4df', 
    accent: '#5e8776 ',
}

export const blueBlack = {
    primary: '#ebfeff ',
    secondary: '#001414', 
    primaryButton: '#3b98fc' ,
    secondaryButton: '#010123',
    accent: '#01001e', 
}

export const purpleWhite = {
    primary: '#0d0411', 
    secondary: '#f8eefb', 
    primaryButton: '#a941d2', 
    secondaryButton: '#ffffff ',
    accent: '#2d0e3a', 
}

export const purpleDark = {
    primary: '#0f051a', 
    secondary: '#e4d4f7',
    primaryButton: '#5f20a7', 
    secondaryButton: '#d4baf2', 
    accent: '#1d0a33', 
}

export const blueWhitePurple = {
    primary: '#031416',
    secondary: '#ffffff',
    primaryButton: '#1c5ece',
    secondaryButton: '#efedfd',
    accent: '#8474ec',
}
export const greenBlue = {
    primary: '#022c15',
    secondary: '#ddfeeb',
    primaryButton: '#26f7ed',
    secondaryButton: '#f5faff',
    accent: '#1d95f7',
}
export const lightPurple={
    primary: '#050308', 
    secondary: '#f1ecf9',
    primaryButton: '#903399', 
    secondaryButton: '#f2d9e8', 
    accent: '#4d1937', 
}
export const purplePurple={
    primary: '#18032b',
    secondary: '#e9d4fc', 
    primaryButton: '#8e1ef1', 
    secondaryButton: '#ddbcfb', 
    accent: '#820fe6', 
}

export const earthyOrange = {
    primary: '#241805',
    secondary: '#faefdb',
    primaryButton: '#e4a73f',
    secondaryButton: '#f6e2c1',
    accent: '#e09d29',
}
export const allBlue = {
    primary: '#e4f9fc',
    secondary: '#052529',
    primaryButton: '#77e0ee',
    secondaryButton: '#083d44',
    accent: '#60dbeb',
}