import React from 'react'
import { AccordionWrapper } from './StyledComponents'

const Accordion = ({children}) => {
  return (
   <AccordionWrapper>
        {children&& children.map(child=>{
            return child
        })}
   </AccordionWrapper>
  )
}

export default Accordion