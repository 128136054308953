import styled from "styled-components";
import {ReactComponent as DoubleArrow} from '../../assets/angleDoubleDown.svg';

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
`

export const AccordionElementWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    
    text-align: left;
    align-items: center;
    max-width: 50vw;
    div + div {
    text-indent: 1.5rem;
    max-height: 0;
    overflow: hidden;
    
    transition: max-height 0.3s ease-out;
    
    opacity: .7;
    color: ${props=>props.theme.primary};
    transition: padding .3s ease;
    border-radius: 0 0 .5rem .5rem;
    
  }
  @media screen and (max-width:1100px) {
       max-width: 100%;
    }

  &.open {
    div + div {
      max-height: fit-content;
      padding: 1rem;
      transition: max-height 0.3s ease-in;
      
    }
  }
`
export const AccordionContent = styled.div`
  box-shadow: ${props=>props.theme.secondary} 0px 0px 0px 2px inset, ${props=>props.theme.accent} 0px 4px 6px -1px , ${props=>props.theme.primary} 0px 1px 0px inset;
  p{
    
    color: ${props=>props.theme.primary};
    mix-blend-mode: darken;
  }
  &.closed{
    box-shadow: none;
  }
  
`
export const AccordionElementContainer = styled.div`
    position: relative;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    background-color: ${props=>props.theme.primaryButton};
    color: ${props=>props.theme.secondary};
    border-radius: .5rem;
    opacity: 0.8;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: border-radius .1s ease-in-out;
    &.open{
      border-radius: .5rem .5rem 0 0;
    }
    &:hover{
      opacity: 1;
    }
    h3{
      flex: 1;
      text-align: center;
    }
    span{
      flex:0;
      text-align: right;
      
    }
    
`

export const Arrow = styled(DoubleArrow)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 4cqh;
  max-width: 4cqw;
  transition: transform .3s ease-in-out;
  fill: ${props=>props.theme.secondaryButton};
  &.open{
    transform: rotate(180deg);
  }
  
`