import styled from "styled-components";


export const SliderWrapper = styled.div`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    display: flex;    
    flex-direction: column;
`
export const SliderContainer = styled.div`
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: auto;
`
export const SliderElement = styled.div`
    flex: 0 0 100%;
    transition: transform 250ms ease-in-out;
    aspect-ratio: 4/3;
    min-width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    
    &.noanimate{
        transition: none;
        
    }
    &.showend{
        justify-content: flex-end;
    }
    &.next{
        transform: translateX(-100%);
    }
    &.prev{
        
        transform: translateX(+100%);

    }
`
export const LeftArrowButton = styled.button`
    position: absolute;
    display: flex;
    cursor: pointer;
    z-index: 1;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 100%;
    opacity: 0;
    color: ${props=>props.theme.secondary};
    background-color: ${props=>props.theme.primary};
    border: 1px solid ${props=>props.theme.primary};
    left: 0;
    &::after{
        content: '⋘ PREV';
        writing-mode: vertical-lr;
        text-orientation: upright;
        font-weight: bold;
        font-size: large;
    }
    &.hide{
        display: none;
    }
    &:hover{
        opacity: 0.5;
    }
    @media (hover:none) and (pointer:coarse){
        display: none;
    }
    @media screen and (max-width:800px) {
       display: none;
    }
`
export const RightArrowButton = styled.button`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    width: 3rem;
    height: 100%;
    opacity: 0;
    
    color: ${props=>props.theme.secondary};
    background-color: ${props=>props.theme.primary};
    border: 1px solid ${props=>props.theme.primary};
    right: 0;
    &::after{
        content: '⋙ NEXT';
        writing-mode: vertical-lr;
        text-orientation: upright;
        font-weight: bold;
        font-size: large;
    }
    &.hide{
        display: none;
    }
    &:hover{
        opacity: 0.5;
    }
    @media (hover:none) and (pointer:coarse){
        display: none;
    }
    @media screen and (max-width:800px) {
       display: none;
    }
`