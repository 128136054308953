import React, { useContext, useState } from 'react';
import {useFormik } from 'formik';
import {
  BoldLink,
  BoxContainer,
  FieldContainer,
  FieldError,
  FormContainer,
  FormError,
  FormSuccess,
  Input,
  MutedLink,
  SubmitButton,
} from './common';
import { Marginer } from '../marginer';
import { AccountContext } from './accountContext';
import * as yup from 'yup';
import axios from '../../api/BDPAPI';
import { PHONE_REGEX, PASSWORD_REGEX } from '../../utils/auth';
import { AppContext } from '../../context/AppContext';



const validationSchema = yup.object({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  phone: yup
    .string()
    .matches(PHONE_REGEX, 'Please enter a valid phone number')
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .matches(PASSWORD_REGEX, 'Please enter a strong password')
    .required(),
  confirmPassword: yup
    .string()
    .when('password', {
      is: val => (val && val.length > 0 ? true : false),
      then: ()=>yup
        .string()
        .oneOf([yup.ref('password')], 'Password does not match'),
    })
    .required('Please confirm your password'),
});

export function SignupForm(props) {
  const { switchToSignin } = useContext(AccountContext);
  const { addNotification } = useContext(AppContext);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  
  
  
  const onSubmit = async values => {
    const { confirmPassword, ...data } = values;    
    try {
      const response = await axios
        .post('users/register', data)
        .catch(err => {
          if (err.response) {
            addNotification({
              content: err.response.data.detail,
              type: 'error',
              id: 'registerError',
            });
          } else if (err.request) {
            // The request was made but no response was received
            console.log(err.request);
            addNotification({
              content: 'Server error has happend',
              type: 'error',
              id: 'registerError',
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
          }
        });
      if (response && response.data) {
        setError(null);
        addNotification({
          content: `Successfully Registered, thanks ${values.first_name} ${values.last_name}`,
          type: 'success',
          id: 'registerSuccess',
          duration: 5000,
        });
        setSuccess(response.data.message);
        formik.resetForm();
        switchToSignin();
      }
    } catch (e) {
      console.log(e.message);
      setError('an Error has occured');
      addNotification({
        content: 'An error has occured',
        type: 'error',
        id: 'registerError',
      });
      setSuccess(null);
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    initialErrors: { manuallyIndicatingAn: 'Error' },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <BoxContainer>
      {!error && <FormSuccess>{success ? success : ''}</FormSuccess>}
      {!success && <FormError>{error ? error : ''}</FormError>}
      <FormContainer onSubmit={formik.handleSubmit}>
        <FieldContainer>
          <Input
            name="first_name"
            type="text"
            placeholder="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FieldError>
            {formik.touched.first_name && formik.errors.first_name
              ? formik.errors.first_name
              : ''}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <Input
            name="last_name"
            type="text"
            placeholder="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FieldError>
            {formik.touched.last_name && formik.errors.last_name
              ? formik.errors.last_name
              : ''}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <Input
            name="phone"
            type="phone"
            placeholder="Phone Number"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FieldError>
            {formik.touched.phone && formik.errors.phone
              ? formik.errors.phone
              : ''}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <Input
            name="email"
            type="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FieldError>
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ''}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FieldError>
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : ''}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <Input
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FieldError>
            {formik.touched.confirmPassword &&
            formik.errors.confirmPassword
              ? formik.errors.confirmPassword
              : ''}
          </FieldError>
        </FieldContainer>
        <Marginer direction="vertical" margin="lem" />
        <SubmitButton type="submit" disabled={!formik.isValid}>
          Signup
        </SubmitButton>
      </FormContainer>
      <Marginer direction="vertical" margin={5} />
      <MutedLink href="#">
        Already have an account?
        <BoldLink href="#" onClick={switchToSignin}>
          Signin
        </BoldLink>
      </MutedLink>
      <Marginer direction="vertical" margin="1em" />
    </BoxContainer>
  );
}
