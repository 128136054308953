import React from 'react';

const AKC = () => {
  return (
    <p>
      As proud members of the American Kennel Club (AKC), all our Boxers
      come with AKC registration papers. This certification verifies the
      breed authenticity and serves as a record of your Boxer's pedigree.
      The AKC is widely recognized as a prestigious and reputable
      organization dedicated to promoting and preserving the integrity of
      dog breeds. Our commitment to breeding AKC-registered Boxers is a
      testament to our dedication to upholding breed standards and ensuring
      the highest quality in our breeding program.
    </p>
  );
};

export default AKC;
