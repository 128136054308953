import React from 'react';

const Visiting = () => {
  return (
    <p>
      We warmly welcome prospective Boxer owners to visit our breeding
      program. We understand the importance of firsthand experience and
      encourage individuals and families to meet our Boxers and see our
      facilities in person. During your visit, we'll be delighted to answer
      any questions you may have, provide insights into our breeding
      practices, and help you choose the perfect Boxer that matches your
      preferences and lifestyle.
    </p>
  );
};

export default Visiting;
