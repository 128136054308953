import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import {
  ModalContainer,
  Overlay,
  ModalBox,
  ModalCloseButton,
} from './StyledComponents';

const Modal = ({ type, overlayActive, position, content, onClose }) => {
  const { theme } = useContext(AppContext);
  const [color, setColor] = useState();
  const [open, setOpen] = useState(true);
  const [displayed, setDisplayed] = useState(true);
  useEffect(() => {
    function determineColor() {
      if (type === 'success') {
        setColor(theme.success);
      } else if (type === 'error') {
        setColor(theme.error);
      } else {
        setColor(theme.background);
      }
    }
    determineColor();
  }, [type, theme]);

  function clear() {
    setOpen(false);
    setDisplayed(true);
  }

  function exitClick() {
    setTimeout(() => {
      clear();
    }, 500);
    setDisplayed(false);
    setTimeout(() => {
      onClose();
    }, 500);
  }

  return (
    <>
      {overlayActive && <Overlay onClick={exitClick} />}
      <ModalContainer className={`${position} ${open ? 'show' : 'hide'}`}>
        <ModalBox
          className={`${displayed ? 'show' : 'hide'}`}
          color={color}
        >
          {content}
          <ModalCloseButton onClick={exitClick}>×</ModalCloseButton>{' '}
        </ModalBox>
      </ModalContainer>
    </>
  );
};

export default Modal;
