import React, { useContext } from 'react';
import {
  CardContainer,
  ColorHeader,
  ContentContainerCol,
  ContentContainerRow,
  FemaleIcon,
  InfoContainer,
  MaleIcon,
  NameContainer,
  PictureContainer,
  SVG,
  SalesContainer,
  SalesHeader,
} from './StyledComponents';
import { useDogPictures } from '../../hooks/apiHooks/useDogPictures';
import { AppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

const PuppyCard = ({ picture, dog, onClick = () => {} }) => {
  const { setSelectedAvailableDog, setSelectedDogPictures } =
    useContext(AppContext);
  const { getDogPictures, error: picError } = useDogPictures({});
  let navigate = useNavigate();
  const convertGender = gender => {
    if (gender === 'male') {
      return <MaleIcon />;
    }
    return <FemaleIcon />;
  };
  const handleClick = () => {
    onClick();
    console.log(dog);
    setSelectedAvailableDog(dog);
    setSelectedDogPictures(getDogPictures(dog.id));
    navigate(`/puppies/${dog.id}`);
  };
  return (
    <CardContainer onClick={() => handleClick()} gender={dog.gender}>
      <SVG>{convertGender(dog.gender)}</SVG>
      <NameContainer>{dog.name}</NameContainer>

      <ContentContainerRow>
        <PictureContainer>{picture}</PictureContainer>

        <InfoContainer>
          <ContentContainerCol>
            <ColorHeader>{dog.color}</ColorHeader>
          </ContentContainerCol>
        </InfoContainer>
      </ContentContainerRow>

      <SalesContainer>
        <ContentContainerCol>
          <SalesHeader>{dog.sales_header}</SalesHeader>
          <p>{dog.sales_summary}</p>
        </ContentContainerCol>
      </SalesContainer>
    </CardContainer>
  );
};

export default PuppyCard;
