import { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { useAxiosFunction } from './useAxios';
import axios from '../../api/BDPAPI';

export const useGetIP = () => {
  const { response, loading, axiosFetch } = useAxiosFunction();
  const [fetched, setFetched] = useState(false);

  const fetchIP = async (forceFetch = false) => {
    
      if (!fetched || forceFetch) {
        let res = {};
        // console.log(
        // 'fetching forced:' + forceFetch + ' url:/ip',
        // );
        res = await axiosFetch({
        axiosInstance: axios,
        method: 'GET',
        url: '/ip',
        requestConfig: {
            headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
        }).catch(e => console.error(e));
        setFetched(true);
        if (Object.keys(res).length > 0) {
          console.log(res.data.X_Forwarded_For)
          return res.data;
        } else {
          return null;
        }
      }
    
  };

  return { response, loading, fetchIP };
};
