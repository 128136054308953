import { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { useAxiosFunction } from './useAxios';
import axios from '../../api/BDPAPI';

export const useDogs = ({
  currentData = [],
  onSuccess = null,
  onFail = null,
}) => {
  const { setDogs, setSelectedDog, setSelectedAvailableDog } =
    useContext(AppContext);
  const { response, loading, error, setError, axiosFetch } =
    useAxiosFunction();
  const [fetched, setFetched] = useState(false);

  const fetchDogs = async (forceFetch = false) => {
    if (currentData.length === 0 || forceFetch) {
      if (!fetched || forceFetch) {
        let res;
        if (!sessionStorage.getItem('token')) {
          res = await axiosFetch({
            axiosInstance: axios,
            method: 'GET',
            url: '/dogs/history',
          }).catch(e => {
            console.error(e);
          });
        } else {
          //   console.log('fetching forced:' + forceFetch + ' url:/employees/');
          res = await axiosFetch({
            axiosInstance: axios,
            method: 'GET',
            url: '/dogs/',
            requestConfig: {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
            },
          }).catch(e => {
            console.error(e);
          });
        }
        setFetched(true);
        if (res) {
          setDogs(res.data);
          return res.data;
        }
      }
    }
  };

  const postDogs = async data => {
    if (data.birth_date.includes('undefined')) {
      const a = data.birth_date.split('-');
      const b = a[1] + '-' + a[2] + '-' + a[3];
      data.birth_date = b;
    }
    if (data.estimated_ready_date.includes('undefined')) {
      const a = data.estimated_ready_date.split('-');
      const b = a[1] + '-' + a[2] + '-' + a[3];
      data.estimated_ready_date = b;
    }
    try {
      let res = await axiosFetch(
        {
          axiosInstance: axios,
          method: 'POST',
          url: '/dogs/',
          requestConfig: {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        },
        data,
      );
      if (res) {
        await fetchDogs(true);
        if (onSuccess !== null) {
          onSuccess();
        }
      }
    } catch (e) {
      if (onFail !== null) {
        onFail(e);
      }
    }
  };
  const putDogs = async data => {
    if (data.birth_date.includes('undefined')) {
      const a = data.birth_date.split('-');
      const b = a[1] + '-' + a[2] + '-' + a[3];
      data.birth_date = b;
    }
    if (data.estimated_ready_date.includes('undefined')) {
      const a = data.estimated_ready_date.split('-');
      const b = a[1] + '-' + a[2] + '-' + a[3];
      data.estimated_ready_date = b;
    }
    try {
      let res = await axiosFetch(
        {
          axiosInstance: axios,
          method: 'PUT',
          url: '/dogs/',
          requestConfig: {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        },
        data,
      );
      if (res) {
        await fetchDogs(true);

        if (onSuccess !== null) {
          onSuccess(res.data.message);
        }
        return res.data;
      }
    } catch (e) {
      if (onFail !== null) {
        onFail(e);
      }
    }
  };
  const getDog = async id => {
    try {
      let res = await axiosFetch({
        axiosInstance: axios,
        method: 'GET',
        url: `/dogs/available/${id}`,
        requestConfig: {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      });
      if (res) {
        setSelectedAvailableDog(res.data);
        setFetched(true);
        if (onSuccess !== null) {
          onSuccess();
        }
      }
    } catch (e) {
      if (onFail !== null) {
        onFail(e);
      }
    }
  };
  return {
    response,
    loading,
    error,
    fetchDogs,
    postDogs,
    putDogs,
    getDog,
  };
};
