import { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { useAxiosFunction } from './useAxios';
import axios from '../../api/BDPAPI';

export const useDogPictures = ({
  currentData = [],
  onSuccess = null,
  onFail = null,
}) => {
  const { setDogPictures, setSelectedDogPictures } = useContext(AppContext);
  const { response, loading,error,setError, axiosFetch } = useAxiosFunction();
  const [fetched, setFetched] = useState(false);

  const fetchDogPictures = async (forceFetch = false) => {
    if (currentData.length === 0 || forceFetch) {
      if (!fetched || forceFetch) {
        //   console.log('fetching forced:' + forceFetch + ' url:/employees/');
        let res = await axiosFetch({
        axiosInstance: axios,
        method: 'GET',
        url: '/dogpictures/',
        }).catch(e => setError(e));
        setFetched(true);
        if (res) {
          setDogPictures(res.data);
          return res.data;
        }
      }
    }
  };

  const postDogPictures = async data => {
    try {
      let res = await axiosFetch(
        {
          axiosInstance: axios,
          method: 'POST',
          url: '/dogpictures/',
          requestConfig: {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        },
        data,
      );
      if (res) {
        await fetchDogs(true);
        if (onSuccess !== null) {
          onSuccess();
        }
      }
    } catch (e) {
      if (onFail !== null) {
        setError(e)
        onFail(e);
      }
      setError(e)
    }
  };
  const putDogPictures = async data => {
    try {
      let res = await axiosFetch(
        {
          axiosInstance: axios,
          method: 'PUT',
          url: '/dogpictures/',
          requestConfig: {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        },
        data,
      );
      if (res) {
        await fetchDogs(true);

        if (onSuccess !== null) {
          onSuccess(res.data.message);
        }
        return res.data;
      }
    } catch (e) {
      if (onFail !== null) {
        setError(e)
        onFail(e);
      }
      setError(e)
    }
  };
  const getDogPictures = async id => {
    try {
      let res = await axiosFetch({
        axiosInstance: axios,
        method: 'GET',
        url: `/dogpictures/${id}`,
      });
      if (res) {
        setSelectedDogPictures(res.data);
        if (onSuccess !== null) {
          onSuccess();
        }
      }
    } catch (e) {
      if (onFail !== null) {
        setError(e)
        onFail(e);
      }
      setError(e)
    }
  };
  return {
    response,
    loading,
    error,
    fetchDogPictures,
    postDogPictures,
    putDogPictures,
    getDogPictures,
  };
};
