import styled from 'styled-components';
import { ReactComponent as Picture } from '../../assets/bdpLogo.svg';

export const MainWrapper = styled.div`
  display: flex;
  padding: 1rem;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
export const Header = styled.h1`
  white-space: normal;
`;
export const Content = styled.p``;
export const ImageContainer = styled.div`
  max-width: 30%;
  height: auto;
  height: auto;
  position: relative;
  display: flex;
  @media screen and (max-width: 1100px) {
    max-width: 60%;
  }
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
`;
export const PuppyImg = styled.img`
  min-width: 100%;
  height: auto;
`;

export const ParentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 1rem;
`;
export const Pic = styled(Picture)`
  fill: ${props =>
    props.gender === 'male'
      ? props => props.theme.text2
      : props => props.theme.font};
`;
