import { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { useAxiosFunction } from './useAxios';
import axios from '../../api/BDPAPI';

export const useSelf = ({ currentData = {} }) => {
  const { setUser } = useContext(AppContext);
  const { response, loading, axiosFetch } = useAxiosFunction();
  const [fetched, setFetched] = useState(false);

  const fetchSelf = async (forceFetch = false) => {
    if (Object.keys(currentData).length === 0 || forceFetch) {
      if (!fetched || forceFetch) {
        let res = {};
        console.log(
        'fetching forced:' + forceFetch + ' url:/users/self',
        );
        res = await axiosFetch({
        axiosInstance: axios,
        method: 'GET',
        url: '/users/self',
        requestConfig: {
            headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
        }).catch(e => console.error(e));
        setFetched(true);
        if (Object.keys(res).length > 0) {
          // console.log(res.data)
          setUser(res.data);
          return res.data;
        } else {
          return null;
        }
      }
    }
  };

  return { response, loading, fetchSelf };
};
