import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';

export const useNotificationAutoClose = autoCloseTime => {
  const [removing, setRemoving] = useState('');
  const { notifications, setNotifications } = useContext(AppContext);

  useEffect(() => {
    if (removing) {
      setNotifications(t => t.filter(_t => _t.id !== removing));
    }
  }, [removing, setNotifications]);

  useEffect(() => {
    if (autoCloseTime > 0 && notifications.length) {
      const id = notifications[notifications.length - 1].id;
      setTimeout(() => setRemoving(id), autoCloseTime);
    }
  }, [notifications, autoCloseTime]);
};
