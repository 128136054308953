import React from 'react'
import {ReactComponent as BoxerPup} from '../assets/SadBoxer.svg'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  
`
const SadBoxerSVG = styled(BoxerPup)`
  max-width: 100vw;
  height: auto;
`
const Header = styled.h1`
  /* margin-top: 7.5rem; */
  position: relative;
  display: flex;
  z-index: 1;
  color: ${props=>props.theme.text2};
`

const Error = () => {
  return (
    <Container>
      <SadBoxerSVG/>
      <Header>404 Page Not Found</Header>
    </Container>
  )
}

export default Error