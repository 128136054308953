import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { NavContainer, StyledLink } from './StyledComponents';

const AdminVerticalNavBar = () => {
  const { addModal } = useContext(AppContext);
  const [showBar, setShowBar] = useState(true);
  const location = useLocation();

  return (
    <NavContainer>
      <StyledLink to={'/admin'} onClick={() => {}}>
        Dashboard
      </StyledLink>
      <StyledLink to={'/admin/dogs'} onClick={() => {}}>
        Dogs
      </StyledLink>
      <StyledLink to={'/admin/users'} onClick={() => {}}>
        Users
      </StyledLink>
      <StyledLink to={'/admin/vets'} onClick={() => {}}>
        Vets
      </StyledLink>
      <StyledLink to={'/admin/payments'} onClick={() => {}}>
        Payments
      </StyledLink>
    </NavContainer>
  );
};

export default AdminVerticalNavBar;
