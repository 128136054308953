import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AppContext } from './context/AppContext'

const ProtectedRoute = ({children,path}) => {
  const location = useLocation();
  const {user} = useContext(AppContext);
  if(Object.keys(user).length===0){
    return <Navigate to="/login" state={{from:location}} replace />
  }
  return children
}

export default ProtectedRoute