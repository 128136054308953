import React, { useContext, useEffect } from 'react';
import {
  Content,
  ContentWrapper,
  Header,
  ImageContainer,
  MainWrapper,
  ParentContainer,
  Pic,
  PuppyImg,
} from './StyledComponents';
import Slider from '../../components/slider/Slider';
import PuppyCard from '../../components/cards/PuppyCard';
import { useDogPictures } from '../../hooks/apiHooks/useDogPictures';
import { AppContext } from '../../context/AppContext';

const PuppyInfo = ({ puppy, pics }) => {
  const { dogPictures, selectedDogPictures } = useContext(AppContext);
  const { fetchDogPictures } = useDogPictures({});

  useEffect(() => {
    fetchDogPictures();
    console.log(selectedDogPictures);
  }, []);
  const getPicture = dog => {
    let img = <Pic gender={dog.gender} />;
    dogPictures &&
      dogPictures.map(dogPicture => {
        if (dogPicture.dog.id == dog.id && dogPicture.is_primary == true) {
          img = (
            <PuppyImg
              width={500}
              height={500}
              key={dogPicture.id}
              src={dogPicture.picture.link}
              alt={dogPicture.picture.description}
            />
          );
        }
      });
    return img;
  };
  return (
    <MainWrapper>
      <ContentWrapper>
        <Header>
          {puppy.name} is a {puppy.gender} {puppy.color} {puppy.breed}
        </Header>
        <Content>{puppy.sales_summary}</Content>
        <ParentContainer>
          <PuppyCard
            picture={getPicture(puppy.mother)}
            dog={puppy.mother}
          />
          <PuppyCard
            picture={getPicture(puppy.father)}
            dog={puppy.father}
          />
        </ParentContainer>
      </ContentWrapper>
      <ImageContainer>
        <Slider>
          {selectedDogPictures?.map(pic => {
            return (
              <PuppyImg
                loading="eager"
                width={500}
                height={500}
                key={pic.id}
                src={pic.picture.link}
                alt={pic.picture.description}
                title={pic.picture.description}
              />
            );
          })}
        </Slider>
      </ImageContainer>
    </MainWrapper>
  );
};

export default PuppyInfo;
