import React, { useEffect } from 'react';
import { useContext } from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import { AppContext } from '../../context/AppContext';
import { useModal } from '../../hooks/useModal';
import Modal from './Modal';

const ModalPortalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  gap: 10px;
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  &.hide {
    display: none;
  }
`;

export const ModalPortal = () => {
  const { modals, setModals, hideModals, setHideModals } =
    useContext(AppContext);
  const { loaded, portalId } = useModal();
  const removeModal = id => {
    setModals(modals.filter(m => m.id !== id));
  };
  useEffect(() => {
    if (modals.length === 0 && !hideModals) {
      setHideModals(true);
    } else {
      setHideModals(false);
    }
  }, [modals]);
  return loaded ? (
    ReactDom.createPortal(
      <ModalPortalContainer className={hideModals ? 'hide' : ''}>
        {modals.length > 0 &&
          modals.map(m => (
            <Modal
              key={m.id}
              overlayActive={m.overlayActive}
              position={m.position}
              content={m.content}
              onClose={() => removeModal(m.id)}
            />
          ))}
      </ModalPortalContainer>,
      document.getElementById(portalId),
    )
  ) : (
    <></>
  );
};
