import styled from "styled-components";
import {ReactComponent as BoxerPup} from '../assets/boxerPuppyBrindleHappy.svg'

export const ContentContainer = styled.main`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    position: relative;
    width: 70vw;
    margin-top: 12vh;
    gap: 2rem;
`;
export const AboutContainer = styled.main`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
    width: 70vw;
    margin-top: 12vh;
    @media screen and (max-width:1100px) {
       width: 100%;
       margin-top: 3rem;
    }
`
export const ColContainer=styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    @media screen and (max-width:1100px) {
       align-items: center;
       justify-content: center;
    }
    
`;
export const HomeContainer = styled.main`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    height: auto;
    padding: 1rem;
    @media screen and (max-width:1100px) {
       align-items: center;
       justify-content: center;
    }
`

export const RowContainer=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75vw;
    height: auto;
    gap: 5rem;
    @media screen and (max-width:1100px) {
        width: 100vw;
       flex-direction: column;
       align-items: center;
       justify-content: center;
    }
`
export const Header = styled.h1`
    font-size: clamp(1rem, 10vw, 4rem);
    font-weight: normal;
    font-family: Roboto-Serif;
    color: ${props=>props.theme.primary};
    cursor: default;
    
`
export const Header2 = styled.h2`
    font-size: clamp(1rem, 10vw, 4rem);
    font-weight: normal;
    font-family: Roboto-Serif;
    color: ${props=>props.theme.accent};
    cursor: default;
    
`
export const Paragraph = styled.p`
    --speed:${props=>props.speed};
    --delay:${props=>props.delay};
    font-size: clamp(.8rem,1vw,1rem);
    font-family: Roboto;
    font-weight: normal;
    color: ${props=>props.theme.primary};
    text-align: left;
    opacity: 0;
    padding: 1rem 0;
    
    &.animateplay{
        animation: fadein 1s forwards;
    }
    &.noanimate{
        animation: fadein 0ms forwards;
    }
    @keyframes fadein {
        100%{opacity:1;}
    }
    
`

export const PictureRow= styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70vw;
    gap: 1rem;
    @media screen and (max-width:600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
export const AnimatedPictureContainerLeft =styled.div`
    position: relative;
    width: 35vw;
    height: 30vw;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 25vw;
    opacity: 0;
    animation: leftflyin 2s 7s forwards;
    @keyframes leftflyin {
    from{
        transform: translate(-1000px,0);
        opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
    }
    @media screen and (max-width:600px) {
        width: 90vw;
        height: 85vw;
    }
    &.noanimate{
        animation: leftflyin 0ms forwards;
    }
`
export const AnimatedPictureContainerRight =styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 25vw;
    opacity: 0;
    animation: rightflyin 2s 7s forwards;
    @keyframes rightflyin {
    from {
      transform: translate(1000px, 0);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
    }
    &.noanimate{
        animation: rightflyin 0ms forwards;
    }
`
export const AnimatedHeaderContainer = styled.div`
    --typewriterSpeed:${props=>props.animationSpeed};
    --typewriterDelay:${props=>props.animationDelay};
    display: flex;
    flex-direction: column;
    position: relative;   
    
    h2{
        font-size: clamp(1rem, 2vw, 2rem);
        font-family: Roboto-Mono;
        white-space:nowrap;
    }
    h2::before,
    h2::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    h2::before{
        background: ${props=>props.theme.secondary};
        animation: 
            typewriter var(--typewriterSpeed) steps(${props=>props.textCount}) var(--typewriterDelay) forwards;
    }
    h2::after{
        width: .1em;        
        background-color: ${props=>props.theme.primaryButton};
        /* position: absolute;
        top: 15%; */
        height: 85%;
        animation: typewriter var(--typewriterSpeed) steps(${props=>props.textCount}) var(--typewriterDelay) forwards,
        blinker 750ms steps(2,start) var(--typewriterDelay) infinite;
        
    }
    &.noanimate{
        h2::after,h2::before{
            animation: blinker 0ms forwards,
            typewriter 0ms forwards;
        }
        
    }
    &.hide{
        visibility: hidden;
        h2{
            visibility: hidden;
        }
    }
    @keyframes typewriter {
        to { 
            left:100%;
        }
    }
    @keyframes blinker {
        to{
            visibility: hidden;
        }
    }
`

export const Image = styled.img`
    min-width: 100%;
    height: auto;
    border-radius: 1em;
`

export const PuppyImage = styled.img`
    min-width: 100%;
    height: auto;
`

export const Puppy = styled(BoxerPup)`
    width: 100%;
    height: auto;
`