import styled from 'styled-components';
import { ReactComponent as BoxerLogo } from '../../../assets/bdpLogo.svg';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.primary};
  margin: 2rem;
  font-weight: bold;
  font-size: larger;
  font-family: Roboto-Serif;
  border-bottom: 0.2em solid transparent;
  &.active {
    border-bottom: 0.2em solid ${props => props.theme.accent};
  }
`;
export const UserStyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.primary};
  margin: 1rem;
  font-weight: bold;
  font-size: 1rem;
  font-family: Roboto-Serif;
  border-bottom: 0.2em solid transparent;
  &.active {
    border-bottom: 0.2em solid ${props => props.theme.accent};
  }
`;

export const NavContainer = styled.div`
  background-color: ${props => props.theme.secondary};
  position: relative;
  overflow: hidden;
  min-height: 5rem;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 95vw;
  height: 5rem;
  border-radius: 0.5rem;
  align-items: center;
  align-content: center;
  margin-top: 0.5rem;
  box-shadow: ${props => props.theme.primaryButton} 0px 13px 27px -5px,
    ${props => props.theme.secondary} 0px 8px 16px -8px;
  @media screen and (max-width: 900px) {
    align-items: center;
    justify-content: space-between;
    padding: 0 auto 1em 0.5em;
    position: relative;
  }
  &.hamburger {
    border-radius: 0.5rem 0.5rem 0 0.5rem;
    overflow: visible;
  }
`;
export const HamburgerContainer = styled.div`
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 1.5em;
  height: 1.2em;
  cursor: pointer;
  margin-right: 1rem;
  @media screen and (max-width: 900px) {
    display: flex;
  }
`;
export const Bar = styled.span`
  height: 3px;
  width: 100%;
  background-color: ${props => props.theme.accent};
  border-radius: 10px;
`;

export const NavInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  flex-grow: 1;
  @media screen and (max-width: 900px) {
    position: absolute;
    right: 0;
    flex-direction: column;
    background-color: ${props => props.theme.secondary};
    z-index: 8;
    height: auto;
    gap: 1em;
    top: 5rem;
    justify-content: space-around;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
    border-radius: 0 0 0 1rem;
    box-shadow: ${props => props.theme.primaryButton} 0px 13px 27px -5px,
      ${props => props.theme.secondary} 0px 8px 16px -8px;
  }
  &.active {
    transform: translateX(0%);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`;
export const HamburgerSignupContainer = styled.div`
  overflow: hidden;
  display: none;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    display: flex;
    width: fit-content;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    overflow: visible;
    margin-top: 1em;
  }
`;
export const UserContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: fit-content;
  text-align: center;

  margin: 0.5rem 0 0.5rem 0;

  overflow: hidden;
  @media screen and (max-width: 900px) {
    overflow: visible;
    display: none;
  }
  &.hamburger {
    display: flex;
  }
`;
export const StyledHeader = styled.h1`
  position: relative;
  color: ${props => props.theme.primary};
  font-size: 1rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
`;
export const BoxerSVG = styled(BoxerLogo)`
  position: relative;
  fill: ${props => props.theme.primaryButton};
  width: auto;
  height: 4.5rem;
  cursor: default;
`;
export const LogoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  @media screen and (max-width: 900px) {
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const Button = styled.button`
  text-decoration: none;
  color: ${props => props.theme.primaryButton};
  font-weight: bold;
  font-family: Roboto-Serif;
  font-size: 1rem;
  width: auto;
  height: 100%;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  border-bottom: 0.2em solid transparent;
  cursor: pointer;
  &:hover {
    border-bottom: 0.2em solid ${props => props.theme.accent};
  }
  @media screen and (max-width: 900px) {
    font-size: 0.9rem;
  }
`;
