import React from 'react';

const BDP = () => {
  return (
    <p>
      Big Dawg Plantation is a reputable breeder of Boxer dogs located in
      North Carolina, known for breeding champion bloodline Boxers with a
      focus on producing healthy, well-tempered and structurally sound
      dogs. We have a long-standing reputation for producing some of the
      finest Boxers in the region, and is highly regarded by other breeders
      and Boxer enthusiasts alike. The facility is located on a spacious
      property that provides ample room for their Boxers to exercise and
      play. We place great emphasis on ensuring their dogs have a
      comfortable and healthy living environment, with plenty of access to
      fresh air and natural sunlight. Big Dawg Plantation carefully selects
      breeding pairs based on their health, temperament, and conformation
      to breed standards. We also screen all breeding dogs for genetic
      health issues and performs all the necessary health tests to ensure
      the health of the puppies. The puppies are raised in a loving and
      social environment, where they receive early socialization and
      training to ensure that they develop into well-adjusted, confident,
      and friendly adults. we place a great deal of emphasis on finding
      suitable homes for their puppies, and will only sell to responsible
      and loving owners who can provide the necessary care and attention.
      Overall, Big Dawg Plantation is a dedicated and responsible breeder
      that takes great care in breeding top-quality Boxers with excellent
      bloodlines. With a focus on health, temperament, and conformation,
      they produce some of the best Boxers in North Carolina and are highly
      regarded by the local Boxer community.
    </p>
  );
};

export default BDP;
