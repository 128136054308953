import styled from 'styled-components';
import { ReactComponent as Male } from '../../assets/maleIcon.svg';
import { ReactComponent as Female } from '../../assets/femaleIcon.svg';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  min-height: 23rem;
  max-width: 15rem;
  max-height: 40rem;
  overflow: auto;
  background-color: ${props =>
    props.gender === 'male'
      ? props => props.theme.accent
      : props => props.theme.primaryButton};
  color: ${props =>
    props.gender === 'male'
      ? props => props.theme.secondary
      : props => props.theme.primary};
  opacity: 0.92;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  &:hover {
    transform: scale(1.07);
    opacity: 1;
    div {
      filter: grayscale(0);
      opacity: 1;
    }
  }
  cursor: pointer;
  transition: filter 0.5s ease-in-out;
  border-radius: 1rem;
  @media screen and (max-width: 1200px) {
    div {
      filter: grayscale(0);
    }
  }
`;
export const ColorHeader = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-around;
  padding: 0rem 1rem 0rem 1rem;
  font-size: 0.75rem;
  word-wrap: normal;
`;
export const SalesContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
`;
export const ContentContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const ContentContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const PictureContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  min-width: 8.5rem;
  min-height: 9rem;
  max-width: 10rem;
  max-height: 10rem;
  overflow: hidden;
  filter: grayscale(1);
  border-radius: 0 0.5rem 0.5rem 0;
  margin-top: 1rem;
`;
export const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 1rem;
`;
export const SalesHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
`;

export const MaleIcon = styled(Male)`
  fill: ${props => props.theme.primaryButton};
  width: 100%;
  height: 100%;
`;

export const FemaleIcon = styled(Female)`
  fill: ${props => props.theme.secondaryButton};
  width: 100%;
  height: 100%;
`;
export const SVG = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0.4;
`;
