import React,{useContext, useEffect, useState} from 'react'
import { AnimatedHeaderContainer, AnimatedPictureContainerRight, ColContainer, ContentContainer, Header, Header2, HomeContainer, Image, Paragraph, Puppy, RowContainer } from './StyledComponents'
import { AppContext } from '../context/AppContext'
import {useGetIP} from '../hooks/apiHooks/useGetIP'
import { Helmet } from 'react-helmet-async'

const Home = () => {
  const {setHomeVisited,homeVisited} = useContext(AppContext)
  const [animateTypedText1,setAnimateTypedText1] = useState(true)
  const [animateTypedText2,setAnimateTypedText2] = useState(false)
  const [animatedText2Ended,setAnimatedText2Ended] = useState(false)
  const [animatedText1Ended,setAnimatedText1Ended] = useState(false)
  const typedText1 = 'Say Hello to your' 
  const typedText2 = 'New Family Member...' 
  const HEROPARAGRAPH = 'a beautiful AKC registered champion bloodline boxer puppy! This little \
    bundle of joy is not only adorable but comes from a long line of champions. \
    With a pedigree that boasts impressive bloodlines, you can rest assured that your \
    new furry friend is of the highest quality. These pups have been carefully bred to \
    ensure excellent health, temperament, and conformation. They are incredibly loyal, \
    intelligent, and make fantastic family pets. Your new boxer puppy will come with full \
    AKC registration, up-to-date vaccinations, and a health guarantee. Don\'t miss out on \
    the opportunity to welcome this amazing boxer puppy into your home and family. Place your \
    deposit today and experience the joy and love of owning a champion bloodline boxer!'
  const ANIMATIONSPEED = 3
  const {fetchIP} = useGetIP();
  const printIP=()=>{
    fetchIP();    
  }

  useEffect(()=>{
    if(!homeVisited){
      setTimeout(()=>{
        setHomeVisited(true)        
      },(ANIMATIONSPEED*2+3)*1000)
      setTimeout(()=>{
        setAnimateTypedText1(false)
        setAnimateTypedText2(true)
      },(ANIMATIONSPEED+1)*1000)
    }    
    if(homeVisited){
      setAnimateTypedText2(true)
    }
    
    
  },[])
  
  return (
    <>
    <Helmet>
        <title>Big Dawg Plantation</title>
        <meta name='description' content='Big Dawg Plantation Home Page, find out info about our puppies and our practices' />
        <link rel='canonical' href='/' />
      </Helmet>
      <ContentContainer id='main-content'> 
        <Header>Welcome to Big Dawg Plantation</Header>       
        <RowContainer>
          <HomeContainer>
            <AnimatedHeaderContainer className={homeVisited||!animateTypedText1?'noanimate':''} animationDelay={'1s'} onAnimationEnd={()=>setAnimatedText1Ended(true)} animationSpeed={ANIMATIONSPEED+'s'} textCount={typedText1.length}>
              <Header2>{typedText1}</Header2>
            </AnimatedHeaderContainer> 
            <AnimatedHeaderContainer className={`${homeVisited?'noanimate':''} ${animateTypedText2?'':'hide'}`} onAnimationEnd={()=>setAnimatedText2Ended(true)} animationDelay={ANIMATIONSPEED+1+'s'} animationSpeed={ANIMATIONSPEED+'s'} textCount={typedText2.length}>
              <Header2>{typedText2}</Header2>
            </AnimatedHeaderContainer>
            <Paragraph className={`${homeVisited?'noanimate':''} ${animatedText2Ended?'animateplay':''}`} speed={ANIMATIONSPEED+'s'} delay={ANIMATIONSPEED+2+'s'}>
              {HEROPARAGRAPH}
            </Paragraph>
          </HomeContainer>
                  
          <AnimatedPictureContainerRight className={homeVisited?'noanimate':''}>
            <Puppy/>
          </AnimatedPictureContainerRight> 
        </RowContainer>
         
      {/* <button onClick={printIP}>print ip</button>   */}
    </ContentContainer> 
    </> 
       
  )
}

export default Home