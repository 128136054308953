import React from 'react';

const Delivery = () => {
  return (
    // <p>
    //     For clients unable to visit our facility, we offer safe and reliable delivery options. We work with reputable transportation services that prioritize the well-being and comfort of our Boxers during transit. Rest assured that your Boxer puppy will be handled with the utmost care and attention throughout the delivery process, ensuring a smooth and stress-free journey to their new home.
    // </p>
    <p>
      For clients unable to visit our facility, we offer safe and reliable
      delivery options. We personally deliver your puppy to you and always
      prioritize the well-being and comfort of our Boxers during transit.
      If delivery distance is within a reasonable driving range then we
      offer to door delivery. If you are further away then we will fly your
      puppy in cabin to your closest airport and meet you there for
      delivery. Rest assured that your Boxer puppy will be handled with the
      utmost care and attention throughout the delivery process, ensuring a
      smooth and stress-free journey to their new home. If delivery is
      needed or desired we will happy quote you a price for the option of
      your choice.
    </p>
  );
};

export default Delivery;
