import styled from 'styled-components';

export const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
`;

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MutedLink = styled.h3`
  font-size: 11px;
  color: ${props => props.theme.primary};
  font-weight: 500;
  text-decoration: none;
  cursor: default;
  margin: 2%;
`;

export const BoldLink = styled.a`
  font-size: 11px;
  color: ${props => props.theme.primaryButton};
  font-weight: 700;
  text-decoration: none;
  margin: 0 4px;
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;
  outline: none;
  border: none;
  color: ${props => props.theme.primary};
  background-color: transparent;
  padding: 0px 10px;
  border-bottom: 1.4px solid ${props => props.theme.primary};
  transition: all 200ms ease-in-out;
  font-size: 12px;
  &::placeholder {
    color: ${props => props.theme.primary};
    background-color: transparent;
  }
  &:not(:last-of-type) {
    border-bottom: 1.5px solid ${props => props.theme.accent};
    background-color: transparent;
  }
  &:focus {
    outline: none;
    border-bottom: 2px solid ${props => props.theme.accent};
    background-color: transparent;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.secondary} inset !important;
    box-shadow: 0 0 0 30px ${props => props.theme.secondary} inset !important;
    -webkit-text-fill-color: ${props => props.theme.primaryButton};
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 11px 40%;
  color: ${props => props.theme.secondaryButton};
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 100px 100px 100px 100px;
  cursor: pointer;
  transition: all, 240ms ease-in-out;
  background-color: ${props => props.theme.primaryButton};
  &:focus {
    outline: none;
  }
  &:hover {
    filter: brightness(1.23);
    font-weight: bold;
  }
  &:disabled {
    background-color: ${props => props.theme.secondary};
    filter: contrast(.5);
    color: ${props => props.theme.primary};
    cursor: default;
  }
`;

export const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FieldError = styled.span`
  color: ${props => props.theme.secondary};
  font-size: 11px;
  min-height: 18px;
`;

export const FormSuccess = styled.span`
  color: ${props => props.theme.secondaryButton};
  font-size: 12px;
  min-height: 20px;
  font-weight: 600;
`;

export const FormError = styled.span`
  color: ${props => props.theme.secondary};
  font-size: 12px;
  min-height: 20px;
  font-weight: 600;
`;
