import React from 'react';

const Boxers = () => {
  return (
    <p>
      At our champion AKC Boxer breeding program, we take immense pride in
      our exceptional Boxers. We breed for excellence, focusing on
      producing Boxers that exemplify the breed standards while embodying
      exceptional temperament, health, and conformation. Our Boxers come
      from champion bloodlines, ensuring a lineage of outstanding quality
      and proven performance. Whether you're looking for a loyal companion,
      a potential show dog, or a remarkable working partner, our Boxers are
      bred to excel in all areas.
    </p>
  );
};

export default Boxers;
