import React from 'react'
import { useState } from 'react'
import { AccordionContent, AccordionElementContainer, AccordionElementWrapper, Arrow, DownArrow, UpArrow } from './StyledComponents';


const AccordionElement = ({title,content}) => {
    const [isOpen,setIsOpen] = useState(false);
  return (
    <AccordionElementWrapper className={isOpen ? "open" : ""}>
        <AccordionElementContainer onClick={() => setIsOpen(!isOpen)} className={isOpen?'open':''} >
            <h3>{title}</h3>
            <span ><Arrow className={isOpen?'open':''}/></span>
        </AccordionElementContainer>
      <AccordionContent className={isOpen?'':'closed'}>{content}</AccordionContent>
    </AccordionElementWrapper>
  )
}

export default AccordionElement