import React, { useContext, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Error from './pages/Error';
import Puppies from './pages/Puppies';
import Reviews from './pages/Reviews';
import OurDogs from './pages/OurDogs';
import NavBar from './components/navigation/navBar/NavBar';
import {
  AppContainer,
  ContentContainer,
  FooterContainer,
  HiddenMainContentNav,
  OutletContainer,
} from './StyledComponents';
import SingleDogPage from './pages/SingleDogPage';
import { AccountBox } from './components/accountbox';
import ProtectedRoute from './ProtectedRoute';
import { AppContext } from './context/AppContext';
import Admin from './pages/Admin';
import { useSelf } from './hooks/apiHooks/useSelf';
import ContactForm from './components/contact/ContactForm';
import Dogs from './pages/Dogs';
import Vets from './pages/Vets';
import Users from './pages/Users';
import Payments from './pages/Payments';
import Dashboard from './pages/Dashboard';

const App = () => {
  const { fetchSelf } = useSelf({});
  useEffect(() => {
    // console.log(sessionStorage.getItem('token'))
    if (sessionStorage.getItem('token')) {
      console.log('trying to fetch self');
      fetchSelf();
    }
  }, []);
  const { user } = useContext(AppContext);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute path={'/admin'}>
              <Admin />
            </ProtectedRoute>
          }
        >
          <Route
            index
            element={
              <ProtectedRoute path={'/admin'}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/dogs"
            element={
              <ProtectedRoute path={'/admin/dogs'}>
                <Dogs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/vets"
            element={
              <ProtectedRoute path={'/admin/vets'}>
                <Vets />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <ProtectedRoute path={'/admin/users'}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/payments"
            element={
              <ProtectedRoute path={'/admin/payments'}>
                <Payments />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/about" element={<About />} />
        <Route path="/puppies" element={<Puppies />} />
        <Route path="/puppies/:id" element={<SingleDogPage />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/ourdogs" element={<OurDogs />} />
        <Route path="/login" element={<AccountBox />} />

        <Route path="/*" element={<Error />} />
      </Route>,
    ),
  );
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
};
const Root = () => {
  return (
    <AppContainer>
      <HiddenMainContentNav href="#main-content">
        Skip Navigation
      </HiddenMainContentNav>
      <OutletContainer>
        <NavBar />
        {/* <div className="custom-shape-divider-top-1682854520">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
              </svg>
            </div> */}
        <ContentContainer>
          <Outlet />
        </ContentContainer>
        <FooterContainer>
          <ContactForm />
        </FooterContainer>
      </OutletContainer>
    </AppContainer>
  );
};
export default App;
