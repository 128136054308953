import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import {
  BoxerSVG,
  LogoContainer,
  NavContainer,
  NavInner,
  HamburgerContainer,
  HamburgerSignupContainer,
  Bar,
  Button,
  UserContainer,
  StyledLink,
  UserStyledLink,
} from './StyledComponents';
import { AccountBox } from '../../accountbox';
import { useState } from 'react';

const NavBar = () => {
  const { addModal, user, setUser } = useContext(AppContext);
  const [showHamburger, setShowHamburger] = useState(false);
  const [showOverflow, setShowOverflow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const signinOrUpClick = type => {
    addModal({
      content: <AccountBox setting={type} isModal={true} />,
      overlayActive: true,
      position: 'top-right',
      id: type + 'Modal',
    });
  };
  const hamburgerClick = () => {
    setShowHamburger(!showHamburger);
    if (!showHamburger) {
      setShowOverflow(true);
    } else {
      setTimeout(() => setShowOverflow(!showOverflow), 500);
    }
  };
  const navClick = () => {
    setShowHamburger(false);
    setShowOverflow(false);
  };
  const handleLogout = () => {
    sessionStorage.removeItem('token');
    setUser({});
  };

  return (
    <NavContainer className={showOverflow ? 'hamburger' : ''}>
      <LogoContainer>
        <Link to={'/'}>
          <BoxerSVG
            onClick={navClick}
            role="img"
            title="Logo for bigdawgplantation.com"
            desc="Logo for bigdawgplantation.com that sells boxer dogs and puppies to all of the United States of America"
          />
        </Link>
      </LogoContainer>

      <HamburgerContainer onClick={hamburgerClick}>
        <Bar />
        <Bar />
        <Bar />
      </HamburgerContainer>

      <NavInner className={showHamburger ? 'active' : ''}>
        {Object.keys(user).length > 0 ? (
          <HamburgerSignupContainer
            className={showHamburger ? 'hamburger' : ''}
          >
            <StyledLink
              to={'/admin'}
              onClick={() => console.log('you clicked yourslef')}
            >
              {user.first_name}
            </StyledLink>
            <Button onClick={() => handleLogout()}>SignOut</Button>
          </HamburgerSignupContainer>
        ) : (
          <HamburgerSignupContainer
            className={showHamburger ? 'hamburger' : ''}
          >
            <Button onClick={() => signinOrUpClick('signin')}>
              Login
            </Button>
            <Button onClick={() => signinOrUpClick('signup')}>
              SignUp
            </Button>
          </HamburgerSignupContainer>
        )}

        <StyledLink
          to={'/'}
          onClick={navClick}
          className={location.pathname == '/' ? 'active' : ''}
        >
          Home
        </StyledLink>
        <StyledLink
          to={'/puppies'}
          onClick={navClick}
          className={
            location.pathname.includes('/puppies') ? 'active' : ''
          }
        >
          Puppies
        </StyledLink>
        <StyledLink
          to={'/about'}
          onClick={navClick}
          className={location.pathname == '/about' ? 'active' : ''}
        >
          About
        </StyledLink>
      </NavInner>
      {Object.keys(user).length > 0 ? (
        <UserContainer>
          <UserStyledLink
            to={'/admin'}
            className={location.pathname == '/profile' ? 'active' : ''}
            onClick={() => console.log('you clicked yourself')}
          >
            {user.first_name}
          </UserStyledLink>
          <Button onClick={() => handleLogout()}>SignOut</Button>
        </UserContainer>
      ) : (
        <UserContainer>
          <Button onClick={() => signinOrUpClick('signin')}>Login</Button>
          <Button onClick={() => signinOrUpClick('signup')}>SignUp</Button>
        </UserContainer>
      )}
    </NavContainer>
  );
};

export default NavBar;
