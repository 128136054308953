import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { RowContainer } from './StyledComponents';
import { useDogPictures } from '../hooks/apiHooks/useDogPictures';
import { Helmet } from 'react-helmet-async';
import { useDogs } from '../hooks/apiHooks/useDogs';
import { Navigate, useParams } from 'react-router-dom';
import PuppyInfo from '../layouts/puppy/PuppyInfo';

const SingleDogPage = () => {
  const { selectedAvailableDog, selectedDogPictures } =
    useContext(AppContext);
  const { getDogPictures, error: picError } = useDogPictures({});
  const { getDog, error: dogError } = useDogs({});
  const [picData, setPicData] = useState([]);
  const { id } = useParams();

  const getPics = () => {
    let imgs = [];
    selectedDogPictures &&
      selectedDogPictures.map(dogPicture => {
        imgs.push(dogPicture);
      });
    return imgs;
  };

  useEffect(() => {
    if (picError.length > 0) {
      console.log('there was an error loading pic');
    }
    if (dogError.length > 0) {
      console.log('there was an error loading dog');
    }
  }, [dogError, picError]);
  useEffect(() => {
    getDog(id);
    getDogPictures(id);
  }, []);
  useEffect(() => {
    setPicData(selectedDogPictures);
  }, [selectedDogPictures]);

  if (dogError.length > 0 || picError.length > 0) {
    return <Navigate to={'/error'} replace={true} />;
  }
  return (
    <>
      {Object.keys(selectedAvailableDog).length > 0 ? (
        <>
          <Helmet>
            <title>
              Big Dawg Plantation -{' '}
              {Object.keys(selectedAvailableDog).length > 0
                ? selectedAvailableDog.name
                : 'Unknown Puppy'}
            </title>
            <meta
              name="description"
              content={
                selectedAvailableDog.name +
                ' full info and pictures of the puppy that is available still!'
              }
            />
            <link
              rel="canonical"
              href={'/puppies/' + selectedAvailableDog.id}
            />
          </Helmet>
          <RowContainer>
            {picData.length > 0 ? (
              <PuppyInfo pics={picData} puppy={selectedAvailableDog} />
            ) : (
              <></>
            )}
          </RowContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SingleDogPage;
