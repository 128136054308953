import { isTokenExpired } from './tokenUtil';

export function logout(navigate, clear = null) {
  sessionStorage.removeItem('token');
  if (clear !== null) clear();
  navigate('/login');
}
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
export const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const VIN_REGEX = /^[A-HJ-NPR-Z0-9]{17}$/;

export function expiredClean(navigate) {
  if (isTokenExpired()) {
    logout(navigate);
  }
}