import styled from 'styled-components';

export const ModalBox = styled.div`
  background-color: ${props => props.color || '#fff'};
  box-sizing: border-box;
  /* padding-top: .85rem; */
  border-radius: 0.35em;
  position: relative;
  overflow: hidden;
  transition: transform 300ms ease-in-out, all 300ms linear;
  &.show {
    visibility: visible;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: ${props => props.theme.accent} 0px 2px 4px,
      ${props => props.theme.accent} 0px 7px 13px -3px,
      ${props => props.theme.accent} 0px -3px 0px inset;
    animation: fadeIn 0.5s;
  }
  &.hide {
    animation: fadeOut 0.5s;
  }
  @keyframes fadeIn {
    from {
      transform: translate(100px, 0);
      opacity: 0;
    }
    to {
      transform: translate(-20px, 0);
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    to {
      transform: translateY(-10rem);
      opacity: 0;
    }
    /* from {
      transform: translate(0, 0);
      opacity: 1;
    }
    to {
      transform: translate(100px, 0);
      opacity: 0;
    } */
  }
`;
export const ModalCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0.5rem;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.primary};
  z-index: 10;
  cursor: pointer;
  :hover {
    filter: brightness(1.5);
    transform: scale(1.1);
  }
`;
export const ModalContainer = styled.div`
  position: fixed;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* margin: 10px; */
  width: auto;
  min-width: 15vw;
  z-index: 9;

  &.top-left {
    top: 0;
    left: 0;
  }
  &.top-right {
    top: 1.5rem;
    right: 1rem;
  }
  &.top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &.bottom-left {
    bottom: 0;
    left: 0;
  }
  &.bottom-right {
    bottom: 0;
    right: 0;
  }
  &.bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &.center {
    /* margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content; */

    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
  }
  &.hide {
    visibility: hidden;
  }
`;
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 8;
`;
